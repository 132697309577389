<template>
  <div class="wrap-iframe">
    <iframe
      :src="$appConfig.mapUrl2 + mapUrl"
      width="100%"
      height="100%"
      frameborder="0"
      scrolling="auto"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mapUrl: ''
    }
  },
  methods: {
    getMao() {
      let me = this;
      this.$ajax.get(this.$appConfig.apiUrls.ywxtApi
        + '/api/YW/Getyzturlguidtype?bjid='
        + this.$route.query.instanceId + '&fid='
        + this.$route.query.fid)
        .then((r) => {
          let res = r.data.Data
          if (r.data.IsSuccess) {
            me.mapUrl = '?fid=' + me.$route.query.fid + '&bjid=' + me.$route.query.instanceId + '&guid=' + res.guid + '&type=' + res.type + '&p=1' + '&bjzt=' + res.bjzt;
          } else {
            me.mapUrl = '?fid=' + me.$route.query.fid + '&bjid=' + me.$route.query.instanceId + '&guid=' + '' + '&type=' + '' + res.type + '&p=1' + '&bjzt=' + res.bjzt;
          }
        })
    }
  },
  mounted() {
    this.getMao()
  },
}
</script>

<style scoped>
.wrap-iframe {
  /* border: 1px solid red; */
  /* margin: -10px -10px 0 -10px; */
  height: 100%;
}
</style>
